import axios from 'axios';
import store from '../store/store'
import router from '../router'

const API_URL = process.env.API_URL || ''
// const UNAUTHORIZED = '403';
// const NEEDREFRESH = '401';
let isAlreadyFetchingAccessToken = false

export const apiClient = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        // Authorization: 'Bearer ' + localStorage.token,
        'Cache-Control': 'no-cache'
    },
    timeout: 10000
})


 apiClient.interceptors.request.use(
     config => {
         const token = localStorage.token;
         if (token) {
             config.headers.Authorization = `Bearer ${token}`;
         }
         return config;
     },
     error => Promise.reject(error)
 );






apiClient.interceptors.response.use(function (response) {
    return response
}, async function (error) {
    const {
        config,
        response: {
            status
        }
    } = error
    const originalRequest = config

    if (status.toString() === '401') {
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            await store.dispatch("refreshToken");
            isAlreadyFetchingAccessToken = false;
        } else {
            await new Promise(resolve => setTimeout(resolve, 3000));
        }
        const retryOriginalRequest = new Promise((resolve) => {
            originalRequest.headers.Authorization = 'Bearer ' + localStorage.token,
            resolve(axios(originalRequest))

        })

        return retryOriginalRequest
    }

    if (status.toString() === '403') {
        store.dispatch("logout");
        router.push("/login");
    }

    return Promise.reject(error)
})