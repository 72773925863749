/*jshint esversion: 6 */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import Vue from "vue"
import App from "./App"
import moment from "./moment"
import multiSelect from "./multi-select"
import router from "./router"
import store from "./store/store"

Vue.use(Buefy, {
  defaultIconPack: 'fa',
});

Vue.config.productionTip = false;



/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  moment,
  multiSelect,
  store,
  created () {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
    }
  },
  render: h => h(App)
}).$mount("#app")
