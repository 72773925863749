import {
    apiClient
} from '@/services/config.js';

export default {
    async login(credentials) {
        const response  = await apiClient.post("/api/auth/login", {
            username: credentials.email,
            password: credentials.password
        });
        return response.data;
    },
    async getUser() {
        const response = await apiClient.get("/api/members/me");
        return response.data;
    },
    async isAdmin() {
        const response = await apiClient.get("/api/auth/is-admin");
        return response.data;
    },
    async refreshToken() {
        const response = await apiClient.post("/api/auth/token");
        return response.data;
    },
    async logout() {
        return await apiClient.post("/api/auth/logout");
    }
}
