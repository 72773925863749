import Login from "@/components/Login"
import Logout from "@/components/Logout"
import Vue from "vue"
import Router from "vue-router"

//import Home from "@/components/Pages/Home"
const Home = () => import('@/components/Pages/Home')
const Principal = () => import('@/components/Pages/Principal')


const Infos = () => import("@/components/Pages/Mesinfos")
const Inscription = () => import("@/components/Pages/Inscription")
const Vie = () => import("@/components/Pages/Vie")
const Documents = () => import("@/components/Pages/Documents")
const Coordos = () => import("@/components/Pages/Coordos")
const Services = () => import("@/components/Pages/Services")
const Admin = () => import("@/components/Pages/Admin")
const Badge = () => import("@/components/Pages/Badge")
const Analytics = () => import("@/components/Pages/Analytics")
const Calendrier = () => import("@/components/Pages/Calendrier")
const Exchange = () => import("@/components/Pages/Exchange")
const Propose = () => import("@/components/Pages/Propose")

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: '/',
      component: Principal,
      children: [
        {
          path: "/",
          alias: '/home',
          name: "Home",
          component: Home,
          meta: { requiresAuth: true }
        },
        {
          path: "/services",
          name: "Services",
          component: Services,
          meta: { requiresAuth: true }
        },
        {
          path: "/infos",
          name: "Infos",
          component: Infos,
          meta: { requiresAuth: true }
        },
        {
          path: "/inscription",
          name: "Inscription",
          component: Inscription,
          meta: { requiresAuth: true }
        },
        {
          path: "/vie",
          name: "Vie",
          component: Vie,
          meta: { requiresAuth: true }
        },
        {
          path: "/documents",
          name: "Documents",
          component: Documents,
          meta: { requiresAuth: true }
        },
        {
          path: "/coordos",
          name: "Coordos",
          component: Coordos,
          meta: { requiresAuth: true }
        },
        {
          path: "/badge",
          name: "Badge",
          component: Badge,
          meta: { requiresAuth: true }
        },
        {
          path: "/analytics",
          name: "Analytics",
          component: Analytics,
          meta: { requiresAuth: true }
        },
        {
          path: "/admin",
          name: "Admin",
          component: Admin,
          meta: { requiresAuth: true }
        },
        {
          path: "/logout",
          name: "Logout",
          component: Logout
        },
        {
          path: "/calendrier",
          name: "Calendrier",
          component: Calendrier,
          meta: { requiresAuth: true }
        },
        {
          path: "/exchange",
          name: "Exchange",
          component: Exchange,
          meta: { requiresAuth: true }
        },
        {
          path: "/propose/:id",
          name: "Propose",
          component: Propose,
          meta: { requiresAuth: true }
        },
        {
          path: "*",
          name: "catchall",
          component: Logout
        },
      ]
    },
  ]
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token')

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    next('/login')
  }
  next()
})

export default router
