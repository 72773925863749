<template>
<div></div>
</template>

<script>
export default {
  name: "Logout",
  created() {
    delete localStorage.token;
    delete localStorage.refrehToken;
    this.$store.dispatch("logout");
    this.$router.push("/login");
    
  }
};
</script>
