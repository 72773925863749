import Vue from 'vue'
import Vuex from 'vuex'
import authService from '@/services/authServices'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.user ,
    token: localStorage.token || null,
    admin: localStorage.admin || false,
  },
  mutations: {
    SET_TOCKEN(state, userData) {
      state.token = userData.token;
      localStorage.setItem('token', userData.token);
    },
    SET_USER_DATA(state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData));
    },
    SET_ADMIN(state, isAdmin) {
      state.admin = isAdmin
      localStorage.setItem('isAdmin', isAdmin);
    },
    CLEAR_USER_DATA(state) {
      state.token = null
      state.user = null
      state.admin = false
      localStorage.removeItem('token');
      localStorage.removeItem('user')
      localStorage.removeItem('isAdmin')
    }
  },
  actions: {
    async login({
      commit
    }, credentials) {
      var data = await authService.login(credentials);
      commit('SET_TOCKEN', data);
      data = await authService.getUser();
      commit('SET_USER_DATA', data);
      data = await authService.isAdmin();
      commit('SET_ADMIN', data);
      return true
    },
    async setUser({
      commit
    }) {
      return await authService.getUser()
        .then((data) => {
          commit('SET_USER_DATA', data)
        })
    },
    async refreshToken({
      commit
    }) {
      var data;
      try {
        data = await authService.refreshToken();
        commit('SET_TOCKEN', data)
        return true
      } catch (error) {
        commit('CLEAR_USER_DATA');
        return false;
      }
    },
    async logout({
      commit
    }) {
      try {
        await authService.logout();
      } catch (error) {
        console.error('ERROR CLEARING COOKIE.');
      }
      commit('CLEAR_USER_DATA');
    }
  },
  getters: {
    loggedIn(state) {
      return !!state.token&&!!state.user
    },
    getUser(state) {
      return state.user
    },
    isAdmin(state) {
      return state.admin
    },
    isVolant(state) {
      return state.user.currentTeam.includes("volante")
    }
  }
})