<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "@/mybulma.scss";

export default {
  name: "app",
  computed: {
  },
  created() {
  },
  updated() {
  },
  methods: {
  },
  components: {
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Maitree:300,400,600&display=swap');
@import "~bulma/sass/utilities/mixins.sass";
html {
  background-color: #ffffff;
}

body {
  background: #ffffff;
  font-family: "Open Sans", serif;
}

p {
  font-family: "Open Sans", serif;
}

button {
animation: fade 2s ease forwards;
}

/* Solid border */
hr.solid {
  border-top: 1px solid #dae3ec;
}

.button-rounded {
  border-radius: 25px;
  padding: 0px 20px;
}

.button-rounded i {
  margin-right: 10px;
}

.app-content {
  padding: 0px 20px;
}

.app-main {
  padding-top: 50px;
  transform: translateZ(0);

  @include desktop {
    padding-top: 70px;
  }
}

.head {
  margin: 20px 0px !important;

  @include touch {
    margin: 10px 0px !important;
  }
}


@keyframes fade {
  from {
    opacity: 0;
    pointer-events: none;
  }
  to {
    opacity:1;
    pointer-events: all;
  }
}
.datepicker-cell.is-selected {
  background: #ff4200 !important;
}
.datepicker-cell.is-today {
  border: solid 1px #ff4200 !important;
}
.button.is-dark {
  background:  #d5f5e3 !important;
  color:  #17202a !important;
}
.table td {
  vertical-align: middle !important;
}
.highlight {
  background-color: #d5f5e3 !important;
}
.contact {
  background-color: #ffece6 !important;
  color: #ff4200 !important;
  border: solid 1px #ff4200 !important;
}
.date {
  font-weight: bold !important;
}
.save {
  border: solid 1px #000000 !important;
}
.tag {
  border: solid 0.5px #d5d8dc !important;
}
.locked {
  background-color:  #f2f4f4 !important;
  border: solid 1px #d5d8dc !important;
  color: #b2babb !important;
}
.tag-highlighted {
  color: #ffffff !important;
  background-color: #186a3b !important;
}
.past {
  color:  #a6acaf !important;
  background-color: #f2f3f4 !important;
}
.animation-content {
  position: fixed !important;
  top: 200px !important;
}

.pagination-link.is-current {
  background-color: #ff4200 !important;
  border-color: #ff4200 !important;
  color: #fff;
}
</style>
