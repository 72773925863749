<template>
  <section class="hero is-success is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <h3 class="title has-text-grey">Coopérative La Louve</h3>
          <p class="subtitle has-text-grey">Connectez-vous à votre Espace perso</p>

          <div class="box">
            <figure class="avatar">
              <img src="../assets/Louve_logo.png" />
            </figure>
            <form class="form-signin" @submit.prevent="login">
              <div class="field">
                <div class="control">
                  <input
                    class="input is-large"
                    type="email"
                    placeholder="Votre Email"
                    autofocus
                    required
                    v-model="email"
                    name="email"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <input
                    class="input is-large"
                    type="password"
                    placeholder="Votre mot de passe"
                    v-model="password"
                    name="password"
                  />
                </div>
                <p class="subtitle has-text-danger" v-if="error">
            Erreur d'authentification
            {{ errorMessage }}
          </p>
              </div>
              <div class="field">
                <label class="checkbox">
                  <input type="checkbox" />
                  Se souvenir de moi
                </label>
              </div>
              <button class="button is-primary is-block is-large is-fullwidth">Se connecter</button>
            </form>
          </div>
          <p class="has-text-grey">
            <a
              :href="`${baseUrl}/selfservice/?action=sendtoken`"
              target="_blank"
            >Mot de passe oublié</a> &nbsp;·&nbsp;
            <a href="https://cooplalouve.fr/">La Louve</a>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"


export default {
  data() {
    return {
      email: "",
      password: "",
      error: null,
      errorMessage: null,
      baseUrl: ""
    };
  },
  computed: {
    ...mapGetters({ loggedIn: "loggedIn" })
  },
  mounted() {
    this.baseUrl = window.location.origin
  },
  async created() {
  },
  methods: {
    async login() {
     await this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password
        })
        .then(()=> {
          this.$router.replace(this.$route.query.redirect || "/home")
        })
         .catch(() => {
          this.error = true
        })
    }
  }
};
</script>

<style lang="css" scoped>
html,
body {
  font-family: "Open Sans", serif;
  font-size: 14px;
  font-weight: 300;
}
.hero.is-success {
  background: #f2f6fa;
}
.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.box {
  margin-top: 5rem;
}
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
input {
  font-weight: 300;
}
p {
  font-weight: 700;
}
p.subtitle {
  padding-top: 1rem;
}
</style>
